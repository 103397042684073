import React, { Component } from 'react';

class ContainerMid extends Component {
    constructor(props){
        super(props);
        this.state = {
            collMid: [
                {
                    id: 1,
                    title: 'Innovación en la Administración Pública',
                    text: 'Apuntando a la eficiencia y efectividad de la gestión administrativa y financiera fiscal se impulsan reformas para mejorar los sistemas de contabilidad gubernamental, dirigidas a una mayor eficacia administrativa y rendición de cuentas. fiscal.'
                },
                {
                    id: 2,
                    title: 'Capacitación Continua',
                    text: 'Comprometida con el aprendizaje constante, participando activamente en cursos y certificaciones para permanecer a la vanguardia de la contabilidad y las finanzas públicas'
                },
                {
                    id: 3,
                    title: 'Gestión y Liderazgo',
                    text: 'Dirigiendo equipos hacia la excelencia en la gestión municipal, enfocándose en la transparencia y la responsabilidad social.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="chapter-list border-right-0">
                 {
                    this.state.collMid.map((data,i) => (
                        <div className="chapter-item" key={i}>
                            <h4><span>{data.subtitle} </span> {data.title}</h4>
                            <p>{data.text} </p>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ContainerMid;
