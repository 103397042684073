import React, { Component } from 'react';

class Feature2 extends Component {
    constructor(props){
        super(props);
        this.state = {
           features: [
                {
                    id: 1,
                    icon: 'bi bi-badge2',
                    title: 'Compromiso Comunitario',
                    text: 'Dedicación a servir a la comunidad, escuchando las necesidades de sus habitantes.'
                },
                {
                    id: 2,
                    icon: 'bi bi-badge2',
                    title: 'Transparencia',
                    text: 'Una gestión abierta y honesta de los fondos públicos, asegurando cada peso invertido en el desarrollo municipal.'
                },
                {
                    id: 3,
                    icon: 'bi bi-badge2',
                    title: 'Liderazgo Inclusivo',
                    text: 'Promoviendo la participación activa de todos los sectores de la sociedad en la toma de decisiones.'
                }
                
            ]
        }
    }

    render() {
        return (
            <section className="feature-2 section-padding ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">Habilidades y Valores</h3>
                                <p>Construyendo Comunidad con Integridad y Liderazgo</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        {
                            this.state.features.map((data,index) => (
                                <div className="col-lg-4 col-md-6">
                                    <div className="feature-style-2 mb-4 mb-lg-0" key={data.id}>
                                        <i className={data.icon}></i>
                                        <div className="feature-text">
                                            <h4>{data.title}</h4>
                                            <p>{data.text}</p>
                                        </div>
                                    </div>
                                </div>
                            
                            ))
                        }
                        
                    </div>
                </div>
            </section> 
        );
    }
}

export default Feature2;
