import React from 'react';
import { Route, BrowserRouter,Routes} from 'react-router-dom';
import PrivacyPolicy from './components/layouts/home01/PrivacyPolicy'; // Asegúrate de que la ruta de importación sea correcta

import routes from './components/pages/index';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Rutas existentes */}
        {routes.map((route, idx) => (
          <Route exact path={route.path} element={route.component} key={idx} />
        ))}

        {/* Ruta para PrivacyPolicy */}
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

