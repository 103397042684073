import React, { Component } from 'react';

class FeatureLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Licenciatura en Contaduría',
                    text: 'Formación sólida en la Universidad Autónoma del Estado de Hidalgo, cuna de mi expertise en contabilidad y administración.'
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Maestría en Ingeniería Económico Financiera',
                    text: 'Estudios avanzados en la Universidad La Salle Pachuca, enfocados en la ingeniería de las finanzas y la economía.'
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Certificado en Hacienda Pública Municipal',
                    text: 'Competencias certificadas por CONOCER, reflejo de mi habilidad en la gestión financiera fiscal a nivel municipal.'
                }
            ]
        }
    }

    render() {
        return (
            <div className="topic-items">
            {
                    this.state.collLeft.map((data,i) => (
                        <div className="topic-item" key={i}>
                            <div className="icon-box">
                                <i className={data.icon}></i>
                            </div>
                            <div className="topic-content">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                        </div>
                    
                    ))
            }
            </div>
        );
    }

}

export default FeatureLeft;
