import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Author extends Component {
    constructor(props){
        super(props);
        this.state = {
            listItem: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Liderazgo Transparente',
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Gestión Participativa',
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Educación Continua',
                },
            ]
        }
    }
    render() {
        return (
            <section className="author-section section-padding bg-white" id="author">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">Transparencia y Eficiencia en la Gestión Pública</h3>
                                <p>Liderazgo con Visión y Valores</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="auhtor-img mb-4 mb-lg-0">
                                 <img src="assets/images/bg/author.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 ps-4">
                            <div className="section-heading mb-0">
                                <h3 className="heading-title">Marlene Puertas</h3>
                                <p className="lead">Soy Marlene Puertas, desde hace más de 20 años residente de Mineral de la Reforma, Hidalgo. Mi compromiso es con la integridad y la mejora continua en la administración pública.</p>
                                <p>Con una carrera dedicada a la administración efectiva de los recursos públicos municipales y a la fiscalización efectiva ya la implementación de políticas de transparencia, me esfuerzo por asegurar que cada acción tomada beneficie a la comunidad y refuerce la confianza en nuestras instituciones.</p>
                            </div>

                            <div className="author-desc">
                                <ul className="list-unstyled">
                                    {
                                    this.state.listItem.map((data,i) => (
                                        <li key={data.id}><i className={data.icon}></i> {data.title}</li>
                                        ))
                                    }
                                </ul>
                                <p>Sígueme en</p>
                                <ul>
                                    <li><Link to="https://www.facebook.com/profile.php?id=61555680005672&mibextid=ZbWKwL"> Facebook</Link></li>
                                    <li><Link to="https://www.instagram.com/marlene.puertas_?igsh=MWF4ajlueGU4eDFycQ==">Instagram</Link></li>
                                    <li><Link to="https://www.tiktok.com/@marlene.puertas?_t=8jh3VuuEPkk&_r=1">Tik Tok</Link></li>
                                    <li><Link to="https://x.com/marlene_puertas?t=iFRQ536vclBwdIlywQTN8w&s=09">X</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        );
    }
}

export default Author;
