import React, { Component } from 'react';

class FeatureRight extends Component {
    constructor(props){
        super(props);
        this.state = {
            collRight: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Diplomado en Contabilidad Gubernamental',
                    text: 'Especialización en normativas y sistemas contables del sector público, fortaleciendo mi perfil como auditora y asesora'
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Diplomado en Finanzas Públicas',
                    text: 'Ampliación de mis conocimientos financieros para la optimización de recursos públicos y la toma de decisiones económicas.'
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Talleres y Cursos en Armonización Contable',
                    text: 'Formación continua en la unificación de criterios contables, asegurando transparencia en las cuentas públicas.'
                }
            ]
        }
    }

    render() {
        return (
            <div className="topic-items">
                {
                    this.state.collRight.map((data,i)=> (
                        <div className="topic-item" key={i}>
                            <div className="icon-box">
                                <i className={data.icon}></i>
                            </div>
                            <div className="topic-content">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                        </div>
                    
                    ))
            }
            </div>
        );
    }

}

export default FeatureRight;
