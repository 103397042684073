import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class Intro extends Component {
    render() {
        return (
            <section className="pt-100 center-about bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-heading text-center">
                                <span>Descubra el impacto de un servicio dedicado y experiencia financiera.</span>
                                <h2>Descubre mi camino en administración pública, cuya carrera está marcada por la transparencia, eficiencia y un compromiso con el servicio público.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Intro;
