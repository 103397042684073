import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listItem: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Comprometidos con la Responsabilidad Fiscal',
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Liderazgo comprobado en el Servicio Público ',
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Dominio de la Ingeniería Económica y Financiera',
                },
            ]
        }
    }
    render() {
        return (
            <section className="banner pb-80">
                <div className="container">
                    <div>
                        <h2>"EN CUMPLIENTO CON LO ESTABLECIDO POR LA LEY ELECTORAL, A PATIR DE ESTE MOMENTO SE DEJA DE UTILIZAR ESTE MEDIO PARA FINES POLÍTICOS ELECTORALES".</h2><br /><br />
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12 col-lg-6">
                            <div className="banner-content ">
                                <span className="subheading">Transformando las Finanzas Públicas con Integridad y Eficiencia</span>
                                <h1>Marlene Puertas</h1>
                                <ul>
                                    {
                                        this.state.listItem.map((data, i) => (
                                            <li key={i}><i className={data.icon}></i> {data.title}</li>
                                        ))
                                    }
                                </ul>                              </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-img">
                                <img src="assets/images/banner/booke.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Banner;

