import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About extends Component {
    constructor(props){
        super(props);
        this.state = {
            about: [
                {
                    id: 1,
                    icon: 'bi bi-badge2',
                    title: 'Perfil Profesional',
                    text: 'Más de 15 años liderando la administración de recursos públicos municipales con un enfoque en la innovación y eficacia.'
                },
                {
                    id: 2,
                    icon: 'bi bi-support',
                    title: 'Trayectoria Destacada',
                    text: 'Ocupó posiciones como Jefe de Auditoría y Tesorera Municipal, y se desempeño como asesora estratégica del presidente de la Junta de Gobierno de la LXIV Legislatura del Congreso del Estado de Hidalgo, enfocándose en la legalidad de trasparencia de los paquetes hacendarios.'
                },
                {
                    id: 3,
                    icon: 'bi bi-rocket2',
                    title: 'Compromiso con la Excelencia',
                    text: 'Con integridad y dedicación al servicio público, promoviendo mejoras significativas en la administración de recursos y procesos gubernamentales.'
                }
            ]
        }
    }
    render() {
        return (
        <section className="about-section section-padding bg-grey" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="about-img">
                            <img src="assets/images/banner/booke.jpg" alt="" className="img-fluid"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="section-heading">
                            <span className="subheading">Acerca de </span>
                            <h3 className="heading-title">Experiencia en Administración Pública</h3>
                            <p>Profesional con amplia experiencia en contabilidad y administración pública. Mi carrera abarca roles críticos en la gestión financiera de los gobiernos municipales y capacidades de asesoría.</p>
                        </div>

                        {
                            this.state.about.map((data,i) => (
                                <div className="about-text-block" key={i}>
                                    <i className={data.icon}></i>
                                    <h4>{data.title}</h4>
                                    <p>{data.text} </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>  
        );
    }
}

export default About;
