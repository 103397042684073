import React, { Component } from 'react';

class ContainerLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    title: 'Auditoría Gubernamental',
                    text: 'Implementación de estrategias de fiscalización para la Secretaría de la Contraloría del Gobierno del Estado de Hidalgo, elevando los estándares de transparencia y rendición de cuentas.'
                },
                {
                    id: 2,
                    title: 'Tesorera Municipal',
                    text: 'Gestión y administración de finanzas en diversos municipios de Hidalgo, con un enfoque en la optimización de recursos y la sostenibilidad financiera.'
                },
                {
                    id: 3,
                    title: 'Asesoría Legislativa',
                    text: 'Orientación estratégica en políticas públicas y de contabilidad gubernamental para la Junta de Gobierno de la LXIV Legislatura del Congreso del Estado de Hidalgo, marcando una pauta en el análisis de paquetes hacendarios.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="chapter-list border-right-0">
                 {
                    this.state.collLeft.map((data,i) => (
                        <div className="chapter-item" key={i}>
                            <h4><span>{data.subtitle} </span> {data.title}</h4>
                            <p>{data.text} </p>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ContainerLeft;
