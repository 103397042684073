import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h2>Política de Privacidad</h2>
      <p>
      Aviso de Privacidad
Marlene Puertas by DataCalculus OÜ, es el responsable por el tratamiento de sus datos
personales. Así también su domicilio, que será el mismo para oír y recibir notificaciones, es:Erika 14,
10416 Tallinn, Estonia.

<br /> <h5>Protección de los Datos Personales.</h5>
Marlene Puertas, asume el compromiso de mantener estrictos mecanismos de protección, con la
finalidad de garantizar que el manejo de sus datos se hará con seguridad, integridad, privacidad y
confidencialidad, observando en todo momento lo señalado por el marco normativo de la materia.
<br /><h5>Finalidades del tratamiento a Datos Personales.</h5>
Usamos la información que recopilamos sobre usted:
<br />● Para proporcionarle servicios, productos u otros materiales que solicite (por ejemplo,
completar su registro en un programa de fidelidad o sorteo y enviarle productos).
<br />● Para comunicarnos con usted o enviarle anuncios, por ejemplo, en relación con nuestros
productos, servicios, políticas y promociones.
<br />● Para personalizar su experiencia con nuestros Servicios, incluida publicidad relevante sobre
nuestros Servicios y en otros sitios, como otros sitios web y servicios y redes de medios
sociales.
<br />● Para gestionar y mejorar sus experiencias de navegación. Servicios y ofertas, para
determinar la eficacia de nuestra publicidad.
<br />● Para proteger la seguridad e integridad de nuestros Servicios, así como abordar problemas
de seguridad o privacidad de la información, depurar y detectar incidentes o anomalías de
seguridad, cumplir, responder y documentar solicitudes de derechos de privacidad,
funcionamiento de la red y resolución de problemas.
<br />● Para hacer cumplir nuestros derechos legales.
<br />● Realizar análisis internos, como encuestas, estudios, estudios de mercado, supervisión o
análisis de tendencias, uso y actividades.
<br />● Para cumplir con las políticas de Brocha Gorda, las normas del sector y las leyes, normativas
o procesos legales aplicables.
<br />● Con cualquier otra finalidad con su consentimiento.
<br /><h5>Datos Personales bajo tratamiento.</h5>
Los Datos Personales recabados serán aquellos que usted proporcione directamente a través del
formulario, chat, y a través de medios automatizados de la página de Internet.
<br />Los Datos Personales a los que se dará tratamiento son únicamente: Nombre completo, teléfono, y
correo electrónico
<br />Marlene Puertas, no dará tratamiento a Datos Personales Sensibles ni Patrimoniales y Financieros,
por lo que, de acuerdo a lo establecido por la Ley Federal de Protección de Datos Personales en
Posesión de Particulares, si usted no manifiesta oposición, tácitamente estará dando su
consentimiento para el tratamiento de sus Datos Personales en los términos del presente Aviso de
Privacidad.
<br /><h5>Derechos ARCO.</h5>
Usted tiene el derecho para Acceder a sus Datos Personales bajo posesión de Marlene Puertas y a
los detalles del tratamiento, solicitar, Rectificarlos o Cancelarlos u Oponerse al tratamiento de los
mismos para fines específicos. En todo momento puede revocar el consentimiento otorgado para el
tratamiento de su información, así mismo limitar el uso o divulgación de sus Datos Personales. Para
el ejercicio de sus derechos, es necesario que acuda a la Recepción de Administración, ubicada en la
siguiente dirección: Erika 14, 10416 Tallinn, Estonia. En la Recepción se le entregará una Solicitud,
en la cual deberá especificar su nombre, correo electrónico, teléfono o algún medio para recibir
notificaciones. Así mismo, debe describir de manera clara y precisa los Datos Personales sobre los
que se busca ejercer alguno de los derechos mencionados, y cualquier otro elemento que facilite la
localización de los Datos Personales. Además, se deberá complementar con los documentos
oficiales que acrediten su identidad o, en su caso, la representación legal del titular. Si su solicitud
fuera para ejercer la rectificación de datos, se deberá, además de lo anteriormente señalado, indicar
las modificaciones a realizarse y aportar los documentos que sustente su petición. En un plazo
máximo de 20 días recibirá respuesta sobre la procedencia de su solicitud al medio de contacto
especificado en la misma. Si la respuesta fuera procedente, podrá  hacer efectiva la misma dentro de
los 15 días hábiles siguientes a que se comunique la respuesta. 
<br /><h5>Transferencia de Datos Personales.</h5>
Marlene Puertas se compromete a no transferir sus Datos Personales a terceros, salvo las
excepciones permitidas por la Ley y, en su caso, realizar las citadas transferencias respetando las
condiciones especificadas en la misma.
<br /><h5>Uso de cookies y web beacons.</h5>
Es nuestro deber informarle que la página de Internet, utiliza herramientas conocidas “cookies”
(Archivo de datos que se almacena en el disco duro del equipo de cómputo o del dispositivo de
comunicaciones electrónicas de un usuario al navegar en un sitio de internet específico, el cual
permite intercambiar información de estado entre dicho sitio y el navegador del usuario. La
información de estado puede revelar medios de identificación de sesión, autenticación o preferencias
del usuario, así como cualquier dato almacenado por el navegador respecto al sitio de internet; y
“beacons” (Imagen visible u oculta insertada dentro de un sitio web o correo electrónico, que se utiliza
para monitorear el comportamiento del usuario en estos medios. A través de estos se puede obtener
información como la dirección IP de origen, navegador utilizado, sistema operativo, momento en que
se accedió a la página, y en el caso del correo electrónico, la asociación de los datos). Estos son
generados automáticamente y graban lo que podría ser datos personales cuando se genera una
conexión al sitio, así como también la recopilación de los siguientes datos: Tipo de navegador y
sistema operativo; Dirección de IP y Fecha, hora y lugar de su visita.
<br />Cada usuario puede cambiar la configuración de las denominadas cookies, mediante la realización
de los ajustes necesarios del navegador de Internet que utilice.
<br /><h5>Seguridad de la información y conservación</h5>
Marlene Puertas cuenta con procedimientos para salvaguardar su información, incluidos
procedimientos técnicos, administrativos y físicos destinados a mantener su información segura. Sin
embargo, tenga en cuenta que aunque tomamos medidas razonables para proteger su información,
ningún sitio web, transmisión de Internet, sistema informático o conexión inalámbrica son
completamente seguros.
Además, tomamos medidas para conservar su información sólo durante el tiempo que sea necesario,
con la finalidad para la que fue recopilada, según lo establecido en el contrato, o según lo requiera o
permita la legislación aplicable.
<br /><h5>Cambios en el Aviso de Privacidad.</h5>
Para la atención de novedades legislativas o jurisprudenciales, políticas internas, nuevos
requerimientos para la prestación u ofrecimiento de nuestros servicios y prácticas del mercado,
Marlene Puertas se reserva el derecho de efectuar en cualquier momento modificaciones o
actualizaciones al presente aviso de privacidad.
<br />Las modificaciones se encontrarán disponibles a través de la Recepción de Administración de la
empresa que se encuentra ubicada en: Dirección Erika 14, 10416 Tallinn, Estonia, así como
también por medio de la siguiente dirección de Internet: https://marlenepuertas.com/
      </p>
    </div>
  );
};

export default PrivacyPolicy;