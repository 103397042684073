const menus = [
    {
        id: 1,
        tomenu: "#about",
        namemenu: "Acerca de"
    },
    {
        id: 2,
        tomenu: "#chapters",
        namemenu: "Perfil profesional"
    },
    {
        id: 4,
        tomenu: "#author",
        namemenu: "Sobre mi"
    },
];



export default menus ;