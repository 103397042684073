const menus = [
    {
        id: 1,
        tomenu: "#about",
        namemenu: "About"
    },
    {
        id: 2,
        tomenu: "#chapters",
        namemenu: "chapters"
    },
    {
        id: 3,
        tomenu: "#testimonial",
        namemenu: "testimonial"
    },
    {
        id: 4,
        tomenu: "#author",
        namemenu: "author"
    },
    {
        id: 5,
        tomenu: "#contact",
        namemenu: "Contact"
    },
];



export default menus ;