const links = [
    {
        id: 1,
        tolink: '/',
        namelink: 'Home 01'
    },
    {
        id: 2,
        tolink: 'home02',
        namelink: 'Home 02'
    },
    {
        id: 3,
        tolink: 'home03',
        namelink: 'Home 03'
    },
    {
        id: 4,
        tolink: 'home04',
        namelink: 'Home 04'
    },
];


export default links ;