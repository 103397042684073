import React, { Component } from 'react';

class Counter extends Component {
    constructor(props){
        super(props);
        this.state = {
            counter: [
                {
                },
                {
                },
                {
                },
                {
                }
            ]
        }
    }

    render() {
        return (
            <section className="counter-wrap pb-100">
            </section> 
        );
    }
}

export default Counter;
